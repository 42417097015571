import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { RestService } from './rest.service';
import { TranslateService } from '@ngx-translate/core';

export interface Statistics{
    client_id: number,
    counter:number,
    selfie:number,
    clicks:number,
    mac:string,
    device:string,
    language:string,
    points:string[],
    pdfs: string[],
    galleries: string[],
    events: string[],
    news: string[],
    token: string
}

@Injectable({
    providedIn: 'root'
})

export class StatisticsService {
    public httpOptions: any;
    sesh:Statistics;
    counter:number;
    selfie:number;
    intvl:any;
    clientId:number;
    points:any;
    pdfs: any;
    galleries: any;
    events: any;
    news: any;
    rest:RestService;
    clicks:number;
    mac:string;
    device: string;
    language: any;
    token: any;
    
    constructor(public http: HttpClient, private toastController: ToastController, private translateService: TranslateService) { }

    initStats(){
        clearInterval(this.intvl);
        this.token = this.getToken();
        this.counter = 0;
        this.selfie = 0;
        this.clientId = 0;
        this.points = [];
        this.pdfs = [];
        this.language = '';
        this.galleries = [];
        this.events = [];
        this.news = [];
        this.clicks = 0;
        this.mac = '';
        this.device = '';
        this.intvl = setInterval(() => {
            this.counter++
        }, 1000);
    }

    setSesh(){
        this.sesh = {
            token: this.token,
            client_id: this.clientId,
            counter: this.counter,
            selfie: this.selfie,
            clicks: this.clicks,
            mac: this.mac,
            device: this.device,
            language: this.translateService.currentLang,
            points: this.points,
            pdfs: this.pdfs,
            galleries: this.galleries,
            events: this.events,
            news: this.news
        }

        this.sendStats(this.sesh).subscribe(response => {
            console.log("Estadisticas enviadas: ", response);
        });
    }

    addPoint(point){
        this.points.push(point);
    }

    addPdf(pdf){
        this.pdfs.push(pdf);
    }

    addGallery(gallery){
        this.galleries.push(gallery);
    }

    addEvent(event){
        this.events.push(event);
    }

    addNews(news){
        this.news.push(news);
    }

    sumClicks(){
        this.clicks++;
    }

    setSelfie(){
        this.selfie = 1;
    }

    setCommonInfo(info){
        this.clientId = info.client;
        this.mac = info.mac;
        this.device = info.device;
    }

    public sendStats(sesh){
        return this.http.post('https://cms-iurban.com/api/send-statistics', { sesh }, this.httpOptions);
    }

    //Generar TOKEN
    rand() {
        return Math.random().toString(36).substr(2);
    };
        
    getToken() {
        return this.rand() + this.rand();
    };
}