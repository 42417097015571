import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router'

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  private history: string[] = []

  constructor(private router: Router, private location: Location) { }

  public startSaveHistory():void{
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.history.length == 0){
          this.history.push(event.urlAfterRedirects)
        }else{
          if (!event.urlAfterRedirects.includes('iframe') && this.history[this.history.length-1] != event.urlAfterRedirects){
              this.history.push(event.urlAfterRedirects)
          }
        }
      }
    })
  }

  public getHistory(): string[] {
    return this.history;
  }

  public goBack(): void {
    this.history.pop();
    if (this.history.length > 1) {
      //this.location.back()
      this.router.navigateByUrl(this.history.pop())
    } else {
      this.router.navigateByUrl("/home")
    }
  }

  public getPreviousUrl(): string {
    if (this.history.length > 0) {
        return this.history[this.history.length - 2];
    }
    return 'aa';
  }

}