
import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RestService } from '../../services/rest.service';
import { AvisoLegalPageModule } from './aviso-legal.module';

@Component({
  selector: 'aviso-legal-modal',
  templateUrl: 'aviso-legal.page.html',
})
export class AvisoLegalPage implements OnInit {

  constructor(
    public rest: RestService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private alertCtrl: AlertController) { }

  ngOnInit(): void {
    console.log("Iniciando Avuso Legal");
  }

  ngOnDestroy(): void {
    //this.modalCtrl.dismiss();
  }



}

