import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RestService } from '../services/rest.service';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class iUrbanService {

  constructor(private httpClient: HttpClient, public rest: RestService, private router: Router) {
    
  }

}