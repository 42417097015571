import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
    providedIn: 'root' 
})
  
export class RestService {
    apiUrl = 'https://cms-iurban.com/api/getNewAppJSON';
    //apiUrl = 'http://cms.test/api/getNewAppJSON';
    mac: any;
    standby: any;
    cacheAssets: any;
    response: any;
    app: any;
    AllLanguages: any;
    debug: any;
    mainInfoStats: any;
    urlSmartCity: any;
    networkStatus: boolean = false;
    networkStatus$: Subscription = Subscription.EMPTY;
    muted: any;
    public httpOptions: any;

    constructor(private router: Router, private httpClient: HttpClient, public platform: Platform) {
      this.muted = true;
      this.AllLanguages =  { 
        'es': { name: "Español", code: "es", image: "https://cms-iurban.com/langs/es.svg", msg: "Elige tu Idioma" },
        'en': { name: "Ingles", code: "en", image: "https://cms-iurban.com/langs/en.svg", msg: "Choose your Language" },
        'fr': { name: "Frances", code: "fr", image: "https://cms-iurban.com/langs/fr.svg", msg: "Choisissez votre Langue" },
        'de': { name: "Aleman", code: "de", image: "https://cms-iurban.com/langs/de.svg", msg: "Wähle deine Sprache" },
        'nl': { name: "Holandes", code: "nl", image: "https://cms-iurban.com/langs/nl.svg", msg: "Kies je Taal" },
        'it': { name: "Italiano", code: "it", image: "https://cms-iurban.com/langs/it.svg", msg: "Scegli la tua Lingua" },
        'pt': { name: "Portugues", code: "pt", image: "https://cms-iurban.com/langs/pt.svg", msg: "Escolha seu idioma" },
        'ca': { name: "Catalan", code: "ca", image: "https://cms-iurban.com/langs/ca.svg", msg: "Trieu el vostre Idioma" },
        'va': { name: "Catalan", code: "ca", image: "https://cms-iurban.com/langs/va.svg", msg: "Trieu el vostre Idioma" },
        'gl': { name: "Gallego", code: "gl", image: "https://cms-iurban.com/langs/gl.svg", msg: "Escolla o seu idioma" },
        'ch': { name: "Chino", code: "ch", image: "https://cms-iurban.com/langs/ch.svg", msg: "選擇你的語言" },
      };
    }

    api(params): any{
      return this.httpClient.post(this.apiUrl, params);
    }

    checkLicense(){
      if (!this.app){
        this.router.navigateByUrl('/license-manager');
      }
    }

    isNewISO(){
      return document.domain == "localhost";
    }

    isNewISONew(){
      return document.domain.includes('localhost');
    }

    onImageError(e){
      e.target.src = "/assets/imgs/imageDefault.png";
    }

    public emailSelfie(selfie): any {
      return this.httpClient.post('https://cms-iurban.com/api/enviar-email', { selfie }, this.httpOptions);
    }

    goTo(typeModule, idModule, urlLink){

      //En caso de movil, tablet o pantallas fuera de las siguiente dimensiones deshabilitar selfie
      const height = window.innerHeight;
      const width = window.innerWidth;
      if ((this.getDeviceType() == 'mobile' || this.getDeviceType() == 'tablet' || (height < 1300 && width > 1080)) && typeModule == "selfie"){
        Swal.fire('Oops...', 'Selfie deshabilitado para este dispositivo', 'warning');
        return;
      }

      //En caso de estar OFFLINE no dejar entrar en los siguientes casos
      const requiredInternet = ['link', 'selfie', 'callejero', 'pdf'];
      if (requiredInternet.includes(typeModule) && !this.networkStatus){
        Swal.fire('Oops...', 'Compruebe su conexion a Internet', 'warning');
        return;
      }

      switch (typeModule){
        case 'pointsInterest': this.router.navigate(["/list"], {queryParams: {id: idModule, module: typeModule}}); break;
        case 'link': this.router.navigate(["/iframe"], {queryParams: {link: urlLink}}); break;
        case 'gallery': this.router.navigate(["/gallery"], {queryParams: {id: idModule}}); break;
        case 'menuPdf': this.router.navigate(["/list"], {queryParams: {id: idModule, module: typeModule}}); break;
        case 'selfie': this.router.navigateByUrl('/selfie'); break;
        case 'callejero': this.router.navigateByUrl('/streetmap'); break;
        case 'event': this.router.navigateByUrl('/rss'); break;
        case 'multiLevel': 
          if (this.router.url.includes('multilevel')){
            const loader = document.getElementById('divBackGround');
            loader.style.display = 'block';   
          }
          this.router.navigate(["/multilevel"], {queryParams: {id: idModule}}); 
          break;
        case 'pointsInterestID': this.router.navigate(["/pointinterest"], {queryParams: {id: idModule}}); break;
        case 'pdf': this.router.navigate(["/pdf"], {queryParams: {id: idModule}}); break;
        case 'events': this.router.navigate(["/list-events-news"], {queryParams: {module: typeModule}}); break;
        case 'news': this.router.navigate(["/list-events-news"], {queryParams: {module: typeModule}}); break;  
        default: break;
      }
    }

    onResize(grid){
      const numRows = this.getNumRows()
      console.log("size: ", numRows)
      //http://localhost:8100/?mac=Alhaurin-El-Grande
      //https://appnew.iurban.es/?mac=Alhaurin-El-Grande&standby=true
      let heigth = document.body.clientHeight;
      let width = document.body.clientWidth;
      console.log("Height: ", heigth)
      console.log("Width: ", width)
      if (width < 700) {
        grid.column(1)
      } else {
        grid.column(12)
      }
      
      const actualHeight = grid.getCellHeight()
      console.log("Actual: ", actualHeight)
      if (width == 768 && heigth == 1376){
        console.log("Entroooo")
        //const heigthSmall = this.app.subtoolbar.length > 0 ? 53 : 60;
        let heigthSmall = 20;
        switch(numRows){
          case 5: heigthSmall = this.app.subtoolbar.length > 0 ? 63 : 71; break;
          case 4: heigthSmall = this.app.subtoolbar.length > 0 ? 53 : 60; break;
          case 3: heigthSmall = this.app.subtoolbar.length > 0 ? 53 : 60; break;
          case 2: heigthSmall = this.app.subtoolbar.length > 0 ? 63 : 71; break;
        }
        grid.cellHeight(heigthSmall);
      }
      // else if (width == 1024 && heigth > 1800){
      //   const heigthBig = this.app.subtoolbar.length > 0 ? 71 : 81;
      //   grid.cellHeight(heigthBig);
      // }
      else{
        let heigthBig = 75;
        //if (actualHeight != 75 && actualHeight != 85){
          switch(numRows){
            case 5: heigthBig = this.app.subtoolbar.length > 0 ? 91 : 101; break;
            case 4: heigthBig = this.app.subtoolbar.length > 0 ? 75 : 85; break;
            case 3: heigthBig = this.app.subtoolbar.length > 0 ? 75 : 85; break;
            case 2: heigthBig = this.app.subtoolbar.length > 0 ? 91 : 101; break;
          }
          //const heigthBig = this.app.subtoolbar.length > 0 ? 75 : 85;
          grid.cellHeight(heigthBig);
        //}
      }
    }

    getNumRows(){
      const items = JSON.parse(JSON.stringify(this.app.items));
      let aux = 0;
      let size = 0;
      items.forEach(element => {
        if (element.y > size){
          size = element.y
          aux = element.h
        }
      });
      size = (size + aux)/aux;
      return size;
    }

    isMobile() {
      if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
        return true;
      } else {
        return false;
      }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
    new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`;
        const node = document.querySelector(element);
        if (node){
            node.classList.add(`${prefix}animated`, animationName);
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }
            node.addEventListener('animationend', handleAnimationEnd, {once: true});
        }
    });

    getLocalStandBys(){
      this.app.standBys.forEach(standbys => {
        if (standbys.hasOwnProperty('items')){
          standbys.items.map(element => {
            if (element.Image != null){
              const fullRemoteImagePath = (element.Image).split('/');
              const fullLocalImagePath = "http://localhost/img/" + fullRemoteImagePath[fullRemoteImagePath.length-1];
              const result = this.isNewISO() && this.checkFileExist(fullLocalImagePath);
              if (result == true) {
                  console.info(`SCRIPT-STANDBY: La Imagen ${fullRemoteImagePath} Existe Localmente`)
                  element.Image = fullLocalImagePath;
              }else{
                if (!element.Image.includes("https://cms-iurban.com/")){
                  console.warn(`SCRIPT-STANDBY: La Imagen ${fullRemoteImagePath} No Existe Localmente`)
                  element.Image = "https://cms-iurban.com/" + element.Image;
                }
              }
            }
            if (element.video != null){
              const fullRemoteVideoPath = (element.video).split('/');
              const fullLocalVideoPath = "http://localhost/img/" + fullRemoteVideoPath[fullRemoteVideoPath.length-1];
              const result = this.isNewISO() && this.checkFileExist(fullLocalVideoPath);
              if (result == true) {
                console.info(`SCRIPT-STANDBY: El Video ${fullRemoteVideoPath} Existe Localmente`)
                  element.video = fullLocalVideoPath;
              }else{
                console.warn(`SCRIPT-STANDBY: El Video ${fullRemoteVideoPath} No Existe Localmente`)
              }
            }
          });
        }    
      });
    }

    getLocalSliders(){
      this.app.slider.map(element => {
        const fullRemoteVideoPath = element.url.split('/');
        const fullLocalVideoPath = fullRemoteVideoPath ? "http://localhost/img/" + fullRemoteVideoPath[fullRemoteVideoPath.length-1] : null;
        const result = this.isNewISO() && this.checkFileExist(fullLocalVideoPath);
        if (result == true) {
            console.info('SCRIPT-HOME: Slider Local')
            element.url = fullLocalVideoPath;
        } else {
            console.warn('SCRIPT-HOME: Slider Remoto');
        }
      });
    }
    

    checkFileExist(urlToFile) {
      try{
          const xhr = new XMLHttpRequest();
          xhr.open('HEAD', urlToFile, false);
          xhr.send();
          xhr.status == 404 ? console.warn("No Existe Video Local") : console.info("Existe Video Local");
          return xhr.status == 404 ? false : true;
      }catch{
          console.warn("No es Nueva ISO");
          return false;
      }
    }

    getDeviceType() {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)){
        return "mobile";
      }
      return "desktop";
    };

}  