import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
// import Swiper core and required modules
import SwiperCore, { EffectCreative } from "swiper";
import { RestService } from '../services/rest.service';
import { StatisticsService } from '../services/statistics.service';

// install Swiper modules
SwiperCore.use([EffectCreative]);

@Component({
 selector: 'app-chooselanguage',
 templateUrl: 'chooselanguage.page.html',
 styleUrls: ['chooselanguage.page.scss']
})

export class ChooseLanguagePage implements OnInit{
    interval: any;
    welcomeText: any;
    size: any;
    activeLangs: any;
    logo: any;
    className: any;

    constructor(private router: Router, private modalController: ModalController, private translate: TranslateService, 
        public rest: RestService, public stats: StatisticsService) {

        const length = this.rest.app.langs.length;
        if (length <= 4){
            this.className = 'rounded-button-language';
        }else{
            this.className = 'rounded-button-language-more';
        }
    }

    ngOnInit() {
        this.interval = setTimeout(async () => {
            await this.modalController.dismiss();
        }, 15000);
    }

    async closeModel(code) {
        this.translate.setDefaultLang(code);
        this.translate.use(code);

        //Iniciacion Estadisticas
        this.stats.initStats();
        this.stats.setCommonInfo(this.rest.mainInfoStats);

        window.clearTimeout(this.interval);
        await this.modalController.dismiss();
        this.router.navigateByUrl('/home');
    }

    ionViewWillLeave(){
        window.clearTimeout(this.interval);
    }

}
