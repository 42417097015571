import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular';
import { RestService } from '../services/rest.service';
import QRCode from 'qrcode';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-eventnews-modal',
    templateUrl: 'eventnews-modal.page.html',
    styleUrls: ['eventnews-modal.page.scss']
})

export class EventNewsModalPage implements OnInit {
    @Input('url_qr') url_qr: any;
    @Input('title_qr') title_qr: any;
    interval: any;
    color_corp: any;
    qr: any;
    url: any;
    qrCode: any;
    showQR = false;

    constructor(private router: Router, private modalController: ModalController, public rest: RestService, private translateService: TranslateService,) {
        this.color_corp = this.rest.app.corpColor;
    }

    ngOnInit() {

        if (this.url_qr.hasOwnProperty(this.translateService.currentLang) && this.url_qr[this.translateService.currentLang]){
            this.showQR = true;
            this.qrCode = this.returnQRCode();
        }

        this.interval = setTimeout(async () => {
            await this.modalController.dismiss();
        }, 300000);
    }

    async closeModal() {
        window.clearTimeout(this.interval);
        await this.modalController.dismiss();
    }

    ionViewWillLeave() {
        window.clearTimeout(this.interval);
    }

    ionViewWillEnter() {
        if (!this.rest.isMobile()){
            this.removeLinks();
        }
    }

    returnQRCode() {

        this.qrCode = QRCode;
        const self = this;

        const opts = {
            errorCorrectionLevel: 'H',
            type: 'image/png',
            quality: 1,
            margin: 5,
            color: {
                dark: '#000'
            }
        }

        this.qrCode.toDataURL((this.url_qr && this.url_qr.hasOwnProperty(this.translateService.currentLang) && this.url_qr[this.translateService.currentLang])
            ? this.url_qr[this.translateService.currentLang]
            : this.url_qr['es'], opts, function (err, url) {
                self.qr = url;
            });
        return self.qr;

    }

    returnTextQRCode() {
        const text = (this.title_qr && this.title_qr.hasOwnProperty(this.translateService.currentLang) && this.title_qr[this.translateService.currentLang])
            ? this.title_qr[this.translateService.currentLang]
            : this.translateService.instant('¡Llévatelo contigo!');
        return text;
    }

    removeLinks(){
        let links = document.querySelectorAll('a');
        links.forEach(el => {
          el.remove();
        });
    }

}
