import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule) },
  { path: 'standby', loadChildren: () => import('./standby/standby.module').then( m => m.StandByModule) },
  { path: 'standbyoffline', loadChildren: () => import('./standbyoffline/standbyoffline.module').then( m => m.StandByOfflineModule) },
  { path: 'pdf', loadChildren: () => import('./pdf/pdf.module').then( m => m.PdfModule) },
  { path: 'list', loadChildren: () => import('./list/list.module').then( m => m.ListModule) },
  { path: 'streetmap', loadChildren: () => import('./streetmap/streetmap.module').then( m => m.StreetMapModule) },
  { path: 'howget', loadChildren: () => import('./howget/howget.module').then( m => m.HowGetModule) },
  { path: 'selfie', loadChildren: () => import('./selfie/selfie.module').then( m => m.SelfiePageModule) },
  { path: 'iframe', loadChildren: () => import('./iframe/iframe.module').then( m => m.iframePageModule) },
  { path: 'iframefullscreen', loadChildren: () => import('./iframefullscreen/iframefullscreen.module').then( m => m.iframeFullScreenPageModule) },
  { path: 'pointinterest', loadChildren: () => import('./pointinterest/pointinterest.module').then( m => m.PointInterestModule) },
  { path: 'gallery', loadChildren: () => import('./gallery/gallery.module').then( m => m.GalleryPageModule) },
  { path: 'multilevel', loadChildren: () => import('./multilevel/multilevel.module').then( m => m.MultiLevelModule) },
  { path: 'license-manager', loadChildren: () => import('./license-manager/license-manager.module').then( m => m.LicenseManagerPageModule) },
  { path: 'list-events-news', loadChildren: () => import('./listeventsnews/listeventsnews.module').then( m => m.ListEventsNewsModule) },
  { path: 'rss', loadChildren: () => import('./rss/rss.module').then( m => m.RSSModule) },
  { path: 'videowall', loadChildren: () => import('./videowall/videowall.module').then( m => m.VideoWallModule) },
  { path: '', redirectTo: 'license-manager', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
