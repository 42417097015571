import { Component, ChangeDetectorRef, OnInit, Inject, APP_INITIALIZER, ApplicationInitStatus  } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { NavigationService } from './services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { iUrbanService } from './services/iurban.service';
import { RestService } from './services/rest.service';
import { StatisticsService } from './services/statistics.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {
  // some fields to store our state so we can display it in the UI
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  idleTime: any;

  constructor(private idle: Idle, keepalive: Keepalive, cd: ChangeDetectorRef, private router: Router, @Inject(APP_INITIALIZER) public appInit: ApplicationInitStatus,
    public navigation: NavigationService, private translate: TranslateService, private iurbanservide: iUrbanService, public rest: RestService, public stats: StatisticsService) {
    this.idleTime = idle;
    this.translate.setDefaultLang('es');
    this.translate.use('es');
    this.navigation.startSaveHistory();
    
    // set idle parameters
    idle.setIdle(5); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(100); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
    });
    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      console.log(`${this.idleState} ${new Date()}`)
      this.countdown = null;
      cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    idle.onTimeout.subscribe(() => this.goStandBy() );
    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

    // set keepalive parameters, omit if not using keepalive
    keepalive.interval(15); // will ping at this interval while not idle, in seconds
    keepalive.onPing.subscribe(() => this.lastPing = new Date()); // do something when it pings
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  ngOnInit(): void {
    // right when the component initializes, start reset state and start watching
    this.reset();
  }

  resetTimeout(){
    this.rest.muted = false;
    this.stats.sumClicks();
    if (this.rest.standby){
      this.reset();
    }
  }

  goStandBy(){
    console.log("Timed Out");
    this.idleState = "TIMED_OUT";
    clearInterval(this.stats.intvl);
    if (this.rest.app.standBys && this.rest.app.standBys.length > 0){
      this.router.navigate(["/standby"])
    }
  }

  lookStats(){
    this.stats.setSesh();
  }

}
