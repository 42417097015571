import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Keyboard from "simple-keyboard";
import { RestService } from '../services/rest.service';
import { AvisoLegalPage } from '../components/aviso-legal/aviso-legal.page';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.page.html',
  styleUrls: ['./email-input.page.scss'],
})
export class EmailInputPage {
  color_corp: any;
  value = "";
  keyboard: Keyboard;
  focus: any;
  public form: FormGroup;
  public mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  public politica = false;
  name: any;
  mail: any;

  constructor(
    private rest: RestService, private modalCtrl: ModalController) { }

  ngAfterViewInit() {
    this.color_corp = this.rest.app.corpColor;
    this.keyboard = new Keyboard({
      inputName: "default",
      onChange: input => this.onChange(input)
    });
  }

  send(): void {
    //quitamos el class de error para comprobar de nuevo
    const name = document.getElementById('name') as HTMLInputElement;
    const mail = document.getElementById('mail') as HTMLInputElement;
    name.classList.remove('error');
    mail.classList.remove('error');

    //Comprobamos que el correo es valido y que el nombre no este vacio
    if (!this.validateEmail(mail) || !this.checkMinLengthInput(mail)){
      mail.classList.add('error');
      Swal.fire('Oops...', 'Verifique el correo electronico', 'warning');
    }

    if (!this.checkMinLengthInput(name)){
      name.classList.add('error');
      Swal.fire('Oops...', 'El nombre debe tener un mínimo de 3 caracteres', 'warning');
    }

    if (this.validateEmail(mail) && this.checkMinLengthInput(mail) && this.checkMinLengthInput(name)){
      this.modalCtrl.dismiss({
        'email': mail.value,
        'name': name.value
      });
    }
  }

  onChange = (input: string) => {
    this.value = input;
    if (this.focus){
      this.focus.value = this.value;
    }
  };

  async closeModal() {
    await this.modalCtrl.dismiss();
  }

  focusName(e){
    this.focus = e.target;
    const name = document.getElementById('name') as HTMLInputElement;
    this.keyboard.setInput(name.value, "default");
  }

  focusMail(e){
    this.focus = e.target;
    const mail = document.getElementById('mail') as HTMLInputElement;
    this.keyboard.setInput(mail.value, "default");
  }

   validateEmail(input) {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (input.value.match(validRegex)) {
      return true;
    } 
    return false;
  }

  isChecked(){
    const check = document.getElementById('checkSelfie') as HTMLInputElement;
    check.checked = true
    this.politica = this.politica == false ? true : false;
    check.checked = this.politica ? true : false;
  }

  checkMinLengthInput(input){
    return input.value.length >= 3;
  }

  public async openSubModal(e){
     this.isChecked();
    const modal = await this.modalCtrl.create({
      component: AvisoLegalPage,
    });
    
    await modal.present();
  }

  
  
}

